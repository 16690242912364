import * as React from 'react';
import { Wrapper } from './item.styled';
import { GapSizeType } from '../../../../theme/type/gap-type';

interface FormItemPropsInterface {
    children: React.ReactChild;
    gapTop?: GapSizeType;
    gapBottom?: GapSizeType;
}

export const FormItem: React.FC<FormItemPropsInterface> = ({ children, gapTop = 'none', gapBottom = 'small' }) => {
    return (
        <Wrapper gapTop={gapTop} gapBottom={gapBottom}>
            {children}
        </Wrapper>
    );
};
