import styled, { css } from 'styled-components';
import { getOpacity } from '../../../theme/utils/get-rgb';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['fullWidth'].includes(prop),
})<{
    fullWidth?: boolean;
}>`
    ${({ fullWidth }) => css`
        position: relative;
        height: 100%;
        width: ${fullWidth ? '100%' : 'unset'}};
    `}
`;

export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['active'].includes(prop),
})<{
    active: boolean;
}>`
    ${({ theme, active }) => css`
        transition: filter ${theme.base.transition};
        height: 100%;

        ${active &&
        css`
            filter: blur(0.2rem);
        `}
    `}
`;

export const Loader = styled.div.withConfig({
    shouldForwardProp: (prop) => !['active'].includes(prop),
})<{
    active: boolean;
}>`
    ${({ theme, active }) => css`
        transition: background-color ${theme.base.transition}, opacity ${theme.base.transition},
            visibility ${theme.base.transition};
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        ${active &&
        css`
            opacity: 1;
            background-color: ${getOpacity(theme.palette.background.body, 0.4)};
            visibility: visible;
            pointer-events: all;
        `}
    `}
`;
