import React, { useMemo } from 'react';
import { Wrapper, IconWrapper, IconCloseWrapper, Content } from './alert.styled';
import { IconInfo } from '../icons/info';
import { IconSuccess } from '../icons/success';
import { IconWarning } from '../icons/warning';
import { IconError } from '../icons/error';
import { IconSpinner } from '../icons/spinner/spinner';
import { IconClose } from '../icons/close';
import { GapSizeType } from '../../../theme/type/gap-type';

interface AlertPropsInterface {
    children: React.ReactNode;
    type?: 'success' | 'error' | 'warning' | 'info';
    size?: 'small' | 'medium' | 'large';
    icon?: boolean;
    marginBottom?: GapSizeType;
    marginTop?: GapSizeType;
    loading?: boolean;
    onCloseClick?: () => void;
}

export const Alert: React.FC<AlertPropsInterface> = ({
    children,
    type = 'info',
    icon,
    marginTop,
    marginBottom = 'vsmall',
    loading,
    onCloseClick,
    size = 'medium',
}: AlertPropsInterface) => {
    const iconComponent = useMemo(() => {
        if (type === 'success') {
            return <IconSuccess />;
        }

        if (type === 'warning') {
            return <IconWarning />;
        }

        if (type === 'error') {
            return <IconError />;
        }
        return <IconInfo />;
    }, []);

    return (
        <Wrapper marginTop={marginTop} marginBottom={marginBottom} type={type} size={size}>
            {icon && !loading && <IconWrapper>{iconComponent}</IconWrapper>}
            {loading && (
                <IconWrapper>
                    <IconSpinner />
                </IconWrapper>
            )}
            <Content>{children}</Content>
            {onCloseClick && (
                <IconCloseWrapper
                    onClick={() => {
                        if (onCloseClick) {
                            onCloseClick();
                        }
                    }}
                >
                    <IconClose />
                </IconCloseWrapper>
            )}
        </Wrapper>
    );
};
