import React from 'react';
import { useTheme } from 'styled-components';
import { Wrapper, First, Second, Third } from './spinner-logo.styled';

export const SpinnerLogo: React.FC = () => {
    const theme = useTheme();

    return (
        <Wrapper viewBox="0 0 75 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7817 21.5926V12.5253L30.0986 3.00696L30.3875 3.09256L36.7914 11.2753L38.5702 21.5926H26.474V20.5253C26.474 19.1499 25.3591 18.0349 23.9837 18.0349C22.6083 18.0349 21.4933 19.1499 21.4933 20.5253V21.5926H20.7817Z"
                fill={theme.palette.color.secondary.main}
                fillOpacity="0.4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.1084 21.5926V12.5253L56.4253 3.00696L56.7142 3.09256L64.5411 4.15991L64.8969 21.5926H52.8007V20.5253C52.8007 19.1499 51.6857 18.0349 50.3103 18.0349C48.9349 18.0349 47.8199 19.1499 47.8199 20.5253V21.5926H47.1084Z"
                fill={theme.palette.color.secondary.main}
                fillOpacity="0.4"
            />
            <First
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.18661 29.479C1.59712 32.1245 0.146973 35.6791 0.146973 39.381V39.381C0.146973 45.2756 4.92547 50.0541 10.8201 50.0541C16.7146 50.0541 21.4931 45.2756 21.4931 39.381V11.7983L4.18661 29.479Z"
                fill={theme.palette.color.secondary.main}
            />
            <Second
                x="26.4741"
                y="0.246429"
                width="21.3462"
                height="49.8077"
                rx="10.6731"
                fill={theme.palette.color.secondary.main}
            />
            <Third
                x="52.8008"
                y="0.246429"
                width="21.3462"
                height="49.8077"
                rx="10.6731"
                fill={theme.palette.color.secondary.main}
            />
        </Wrapper>
    );
};
