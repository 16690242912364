import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconUser: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <circle
            cx="12"
            cy="6"
            r="5.25"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <path
            d="M2.25,23.25a9.75,9.75,0,0,1,19.5,0"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
    </Icon>
);
