import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconClose: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <line
            x1="4.5"
            y1="19.5"
            x2="19.5"
            y2="4.5"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5px"
        />
        <line
            x1="4.5"
            y1="4.5"
            x2="19.5"
            y2="19.5"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5px"
        />
    </Icon>
);
