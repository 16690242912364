import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { GapSizeType } from '../../../theme/type/gap-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['type', 'marginBottom', 'marginTop', 'size'].includes(prop),
})<{
    type: 'success' | 'error' | 'warning' | 'info';
    marginBottom?: GapSizeType;
    marginTop?: GapSizeType;
    size: 'small' | 'medium' | 'large';
}>(
    ({ theme, type, marginTop, marginBottom, size }) => css`
        background-color: ${theme.alert[type].background};
        color: ${theme.alert[type].color};
        padding: ${theme.gap.vsmall} ${theme.gap.medium} ${theme.gap.vsmall} ${theme.gap.vsmall};
        font-weight: 700;
        border-radius: ${theme.radius.medium};
        display: flex;
        align-items: center;
        position: relative;
        text-align: left;

        i {
            width: 1.6rem;
            height: 1.6rem;
        }

        a {
            margin: 0;
            color: ${darken(0.2, theme.alert[type].color)};
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        ${size === 'small' &&
        css`
            padding: 0.5rem 0.8rem;
            font-size: 1.2rem;
        `}

        ${size === 'large' &&
        css`
            padding: ${theme.gap.medium} ${theme.gap.medium} ${theme.gap.medium} ${theme.gap.small};
            font-size: ${theme.text.size.xlarge.fontSize};
            line-height: ${theme.text.size.xlarge.lineHeight};

            i {
                width: 2.6rem;
                height: 2.6rem;
            }
        `}

        ${marginBottom &&
        css`
            margin-bottom: ${theme.gap[marginBottom]};
        `}

        ${marginTop &&
        css`
            margin-top: ${theme.gap[marginTop]};
        `}
    `,
);

export const IconWrapper = styled.div(
    ({ theme }) => css`
        margin-right: ${theme.gap.xsmall};
        display: flex;
        align-items: center;
    `,
);

export const IconCloseWrapper = styled.div(
    ({ theme }) => css`
        margin-left: ${theme.gap.large};
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 1rem;
        position: absolute;
        top: 0;
        right: 0;

        i {
            width: 1.2rem;
            height: 1.2rem;
            opacity: 0.5;
        }

        &:hover {
            i {
                opacity: 1;
            }
        }
    `,
);

export const Content = styled.div``;
