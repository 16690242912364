import React from 'react';
import { Spinner } from '../spinner/spinner';
import { Wrapper, Content, Loader } from './loading.styled';
import { SpinnerType } from '../../../theme/type/spinner-type';

interface LoadingPropsInterface {
    children?: React.ReactNode;
    spinner?: SpinnerType;
    active?: boolean;
    fullWidth?: boolean;
}

export const Loading: React.FC<LoadingPropsInterface> = ({
    children,
    spinner = 'default',
    active = false,
    fullWidth,
}) => {
    return (
        <Wrapper fullWidth={fullWidth}>
            {children && <Content active={active}>{children}</Content>}
            <Loader active={active}>
                <Spinner type={spinner} />
            </Loader>
        </Wrapper>
    );
};
