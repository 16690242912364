import styled, { css, DefaultTheme } from 'styled-components';
import { GapSizeType } from '../../../theme/type/gap-type';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';

const getCssDividerMargin = (sizeKey: GapSizeType, theme: DefaultTheme) => `
    margin: ${theme.gap[sizeKey]} 0;
`;

const getResponsiveDividerMargin = (size: object, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        const breakpointKey = item[0] as BreakpointSizeType;
        const dividerSizeKey = item[1] as GapSizeType;

        if (index === 0) {
            return `
                @media screen and (min-width: 0px) {
                    ${getCssDividerMargin(dividerSizeKey, theme)}
                }
            `;
        }

        return `       
            @media ${theme.breakpoints[breakpointKey].min} {
                ${getCssDividerMargin(dividerSizeKey, theme)}
            }
        `;
    });
};

export const Wrapper = styled.hr.withConfig({
    shouldForwardProp: (prop) => !['gap', 'variant'].includes(prop),
})<{
    gap: GapSizeType | object;
    variant?: 'solid' | 'dashed';
}>(
    ({ theme, gap, variant }) => css`
        border: 0;
        border-bottom: 0.1rem ${variant || 'solid'} ${theme.palette.common.divider};
        font-size: 0;
        height: 0;
        position: relative;
        width: 100%;

        ${typeof gap === 'string' &&
        css`
            ${getCssDividerMargin(gap, theme)};
        `}

        ${typeof gap === 'object' &&
        css`
            ${getResponsiveDividerMargin(gap, theme)};
        `}
    `,
);

export const Label = styled.span(
    ({ theme }) => css`
        display: inline-flex;
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 0 1rem;
        background-color: ${theme.palette.color.white.main};
        transform: translate(-50%, -50%);
        font-size: ${theme.text.size.small.fontSize};
        line-height: ${theme.text.size.small.lineHeight};
        color: ${theme.palette.color.gray.main};
        font-weight: 500;
    `,
);
