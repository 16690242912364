import * as React from 'react';

import { Wrapper, Label } from './divider.styled';
import { GapSizeType } from '../../../theme/type/gap-type';

interface DividerPropsInterface {
    children?: string;
    gap?: GapSizeType | object;
    variant?: 'solid' | 'dashed';
}

export const Divider: React.FC<DividerPropsInterface> = ({ gap = 'vlarge', variant, children }) => {
    return (
        <Wrapper as={children ? 'div' : 'hr'} variant={variant} gap={gap}>
            {children && <Label>{children}</Label>}
        </Wrapper>
    );
};
