import styled, { css, keyframes } from 'styled-components';

const rotationAnimation = keyframes`
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
`;

export const Wrapper = styled.svg(
    ({ theme }) => css`
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        display: inline-flex;
        border-top: 0.2rem solid ${theme.spinner.default.start};
        border-left: 0.2rem solid ${theme.spinner.default.end};
        border-right: 0.2rem solid transparent;
        animation: ${rotationAnimation} ${theme.spinner.default.duration} linear infinite;
    `,
);
