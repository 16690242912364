import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../../theme/type/gap-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['gapTop', 'gapBottom'].includes(prop),
})<{
    gapTop: GapSizeType;
    gapBottom: GapSizeType;
}>`
    ${({ theme, gapTop, gapBottom }) => css`
        flex-direction: column;
        width: 100%;

        ${gapTop &&
        css`
            margin-bottom: ${theme.gap[gapTop]};
        `}

        ${gapBottom &&
        css`
            margin-bottom: ${theme.gap[gapBottom]};
        `}
    `}
`;
