import React from 'react';
import { SpinnerDefault } from './default/spinner-default';
import { SpinnerLogo } from './logo/spinner-logo';
import { SpinnerType } from '../../../theme/type/spinner-type';

interface SpinnerPropsInterface {
    type?: SpinnerType;
}

export const Spinner: React.FC<SpinnerPropsInterface> = ({ type = 'default' }) => {
    if (type === 'logo') {
        return <SpinnerLogo />;
    }
    return <SpinnerDefault />;
};
