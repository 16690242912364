import styled, { css, keyframes, ThemeInterface } from 'styled-components';

const breathAnimation = ({ theme }: ThemeInterface) => keyframes`
        0% {
            fill: ${theme.spinner.logo.start};
        }
        50% {
            fill: ${theme.spinner.logo.end};
        }
        100% {
            fill: ${theme.spinner.logo.start};
        }
`;

export const Wrapper = styled.svg`
    width: 3rem;
`;

export const First = styled.path(
    ({ theme }) => css`
        animation: ${breathAnimation} ${theme.spinner.logo.duration};
        animation-iteration-count: infinite;
    `,
);

export const Second = styled.rect(
    ({ theme }) => css`
        animation: ${breathAnimation} ${theme.spinner.logo.duration};
        animation-delay: 0.25s;
        animation-iteration-count: infinite;
    `,
);

export const Third = styled.rect(
    ({ theme }) => css`
        animation: ${breathAnimation} ${theme.spinner.logo.duration};
        animation-delay: 0.5s;
        animation-iteration-count: infinite;
    `,
);
